import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import { HashRouter } from 'react-router-dom'
import './assets/base.scss'
import Main from './Pages/Main'
import configureStore from './config/configureStore'
import { Provider } from 'react-redux'

require('dotenv').config()

const store = configureStore()
const rootElement = document.getElementById('root')

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement,
  )
}

renderApp(Main)

if (module.hot) {
  module.hot.accept('./Pages/Main', () => {
    const NextApp = require('./Pages/Main').default
    renderApp(NextApp)
  })
}
serviceWorker.unregister()
