import React from "react"

export const BankLogo = () => (
    <svg className="bb-logo"
        viewBox="90 250 300 300" xmlns="http://www.w3.org/2000/svg"
    >
        <g className="bb-logo">
            <g>
                <path
                    fill="#2cbacb"
                    className="blue"
                    d="M254.8,367.8v16.9c14.4,5,24.8,18.7,24.8,34.8c0,20.4-16.5,36.8-36.8,36.8s-36.8-16.5-36.8-36.8v-38.3
					c-10,9.7-16.3,23.3-16.3,38.3c0,29.4,23.8,53.1,53.1,53.1s53.1-23.8,53.1-53.1C295.9,394.3,278.4,373.2,254.8,367.8z"
                />
            </g>
            <circle className="black" cx="242.8" cy="419.6" r="20.5" />
            <polygon
                fill="#2cbacb"
                className="blue"
                points="189.7,327.3 189.7,419.6 205.9,419.6 205.9,336.3 			"
            />
            <polygon
                className="black"
                points="222.2,345.2 222.2,419.6 238.5,419.6 238.5,354.1 			"
            />
        </g>
    </svg>
)