export const MainNav = [
    {
        icon: 'pe-7s-cash',
        label: 'Account',
        to: '#/',
        content: [
            {
                icon: 'pe-7s-graph2',
                label: 'Overview',
                to: '#/'
            },
            {
                label: 'History',
                to: '#/'
            },
            {
                label: 'Deposit / Withdrawals',
                to: '#/'
            },
        ]
    },
    {
        icon: 'pe-7s-graph1',
        label: 'Trading',
        content: [
            {
                label: 'Overview',
                to: '#/'
            },
            {
                label: 'Research',
                to: '#/'
            },
            {
                label: 'Robonando Setup',
                to: '#/'
            },
        ]
    },
    {
        icon: 'pe-7s-news-paper',
        label: 'Market',
        content: [
            {
                label: 'Overview',
                to: '#/'
            },
            {
                label: 'Research',
                to: '#/'
            }
        ]
    }
];
