import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { IoIosArrowRoundBack } from "react-icons/io";

import AppMobileMenu from '../AppMobileMenu';

import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';
import { BankLogo } from '../AppMain/BankLogo';

class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        };

    }

    toggleEnableClosedSidebar = () => {
        let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        const MenuToggle = this.state.active ? IoIosArrowRoundBack : BankLogo;
        return (
            <Fragment>
                <div className="app-header__logo bg-light">
                    <div className="logo-src" />
                    <div className="header__pane ml-auto">
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            this.toggleEnableClosedSidebar();
                            this.setState({ active: !this.state.active })
                        }}>
                            <div className="icon-wrapper--menu-toggle bg-dark icon-wrapper icon-wrapper-alt rounded-circle shadow-sm">
                                <div className="icon-wrapper-bg" />
                                <MenuToggle color="#6c757d" fontSize="30px" />
                            </div>
                        </div>
                    </div>
                </div>
                <AppMobileMenu />
            </Fragment>
        )
    }
}


const mapStateToProps = state => ({
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({

    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);