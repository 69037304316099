import React from 'react'

const Loading = () => (
    <div className="bb-loading-indicator bg-dark">
        <svg
            id="bb-loading-indicator"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1280 800"
        >
            <style type="text/css">
                {`.st0{display:none;}
	.st1{display:inline;fill:#FFFFFF;}`}
            </style>

            <g>
                <g className="bb-logo">
                    <g>
                        <path
                            className="blue"
                            d="M254.8,367.8v16.9c14.4,5,24.8,18.7,24.8,34.8c0,20.4-16.5,36.8-36.8,36.8s-36.8-16.5-36.8-36.8v-38.3
					c-10,9.7-16.3,23.3-16.3,38.3c0,29.4,23.8,53.1,53.1,53.1s53.1-23.8,53.1-53.1C295.9,394.3,278.4,373.2,254.8,367.8z"
                        />
                    </g>
                    <circle className="white" cx="242.8" cy="419.6" r="20.5" />
                    <polygon
                        className="blue"
                        points="189.7,327.3 189.7,419.6 205.9,419.6 205.9,336.3 			"
                    />
                    <polygon
                        className="white"
                        points="222.2,345.2 222.2,419.6 238.5,419.6 238.5,354.1 			"
                    />
                </g>
                <g>
                    <path
                        strokeWidth="4"
                        stroke="#fff"
                        className="st3 path white"
                        d="M377.1,422.6c0,16.4-12,28.7-28.6,28.7c-6.6,0-12.5-2.2-17-6.1l-1.7,4.5H319v-73.9h14.7v22.5
				c4.2-2.9,9.3-4.5,14.9-4.5C365.1,393.9,377.1,406.1,377.1,422.6z M347.3,437.4c8.5,0,14.8-6.4,14.8-14.8
				c0-8.5-6.3-14.9-14.8-14.9c-8.5,0-14.8,6.4-14.8,14.9C332.5,431,338.8,437.4,347.3,437.4L347.3,437.4z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#fff"
                        className="st3 white path"
                        d="M384.1,422.6c0-16.5,12.5-28.7,29.4-28.7c16.7,0,29.4,12.2,29.4,28.7c0,16.4-12.6,28.7-29.4,28.7
				C396.6,451.3,384.1,439,384.1,422.6z M427.9,422.6c0-8.8-6-14.9-14.5-14.9c-8.5,0-14.5,6.1-14.5,14.9c0,8.8,6,14.9,14.5,14.9
				C421.9,437.5,427.9,431.4,427.9,422.6z"
                    />
                    <path
                        strokeWidth="4"
                        className="st3 path white"
                        stroke="#fff"
                        d="M504.4,416.8v32.9h-14.7v-30.5c0-8.2-3.1-12.2-9.5-12.2c-7.6,0-12.2,5.4-12.2,14.2v28.5h-14.7v-54.3h10.1
				l2.9,6.7c4.1-4.9,10.6-8,18-8C496.6,394.2,504.4,403.1,504.4,416.8z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#fff"
                        className="st3 path white"
                        d="M573.1,395.4v54.3h-11.5l-1.3-4.9c-4.7,4.1-10.8,6.5-17.6,6.5c-16.3,0-28.5-12.3-28.5-28.8
				c0-16.4,12.2-28.6,28.5-28.6c7,0,13.2,2.5,17.8,6.6l1.6-5.2L573.1,395.4z M558.7,422.5c0-8.5-6.3-14.9-14.8-14.9
				s-14.8,6.4-14.8,14.9s6.3,14.9,14.8,14.9S558.7,431,558.7,422.5z"
                    />
                    <path
                        strokeWidth="4"
                        className="st3 path white"
                        stroke="#fff"
                        d="M638.2,416.8v32.9h-14.7v-30.5c0-8.2-3.1-12.2-9.5-12.2c-7.6,0-12.2,5.4-12.2,14.2v28.5h-14.7v-54.3h10.1
				l2.9,6.7c4.1-4.9,10.6-8,18-8C630.5,394.2,638.2,403.1,638.2,416.8z"
                    />
                    <path
                        strokeWidth="4"
                        className="st3 path white"
                        stroke="#fff"
                        d="M706.3,375.9v73.9h-10.8l-1.7-4.5c-4.6,3.9-10.4,6.1-17,6.1c-16.5,0-28.6-12.3-28.6-28.8
				c0-16.5,12.1-28.7,28.6-28.7c5.6,0,10.7,1.6,14.9,4.5v-22.5L706.3,375.9z M692.7,422.6c0-8.5-6.3-14.9-14.8-14.9
				c-8.5,0-14.8,6.4-14.8,14.9s6.3,14.9,14.8,14.9C686.4,437.5,692.7,431.1,692.7,422.6z"
                    />
                    <path
                        strokeWidth="4"
                        className="st3 path white"
                        stroke="#fff"
                        d="M716.8,422.6c0-16.5,12.5-28.7,29.4-28.7c16.7,0,29.4,12.2,29.4,28.7c0,16.4-12.6,28.7-29.4,28.7
				C729.3,451.3,716.8,439,716.8,422.6z M760.6,422.6c0-8.8-6-14.9-14.5-14.9c-8.5,0-14.5,6.1-14.5,14.9c0,8.8,6,14.9,14.5,14.9
				C754.6,437.5,760.6,431.4,760.6,422.6z"
                    />
                    <path
                        strokeWidth="4"
                        className="st3 path white"
                        stroke="#fff"
                        d="M805.3,451.3c-13.6,0-22.7-7.8-22.8-19.4h14.3c0.1,4.8,3.8,7.3,9,7.3c4.1,0,7.7-1.8,7.7-5.5
				c0-3.7-4.7-4.8-10.5-5.8c-8.7-1.4-19.7-3.8-19.7-16.6c0-10.3,8.7-17.4,21.9-17.4c13.1,0,21.7,7.3,21.8,18.2h-13.9
				c-0.1-4.3-3.1-6.4-8.2-6.4c-4.6,0-7.3,2.1-7.3,5.2c0,3.6,4.6,4.5,10.3,5.5c8.9,1.6,20.2,3.5,20.2,16.7
				C828.1,444.1,819.1,451.3,805.3,451.3L805.3,451.3z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#2CBACB"
                        className="st2 path blue"
                        d="M897.1,422.6c0,16.1-11.6,28.3-27.9,28.3c-9.2,0-17-4-22.1-10.4l-1,9.3h-5.4v-73.6h6.8v28
				c5-6.1,12.7-9.8,21.6-9.8C885.5,394.3,897.1,406.4,897.1,422.6z M890.1,422.6c0-12.5-9-21.7-21.4-21.7
				c-12.3,0-21.5,9.3-21.5,21.7c0,12.3,9.2,21.7,21.5,21.7C881.1,444.3,890.1,435.1,890.1,422.6z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#2CBACB"
                        className="st2 path blue"
                        d="M963.1,395.4v54.3h-5.7l-0.8-9.7c-4.9,6.7-12.9,10.9-22.4,10.9c-16,0-28-12.2-28-28.3
				c0-16.2,12-28.3,28-28.3c9.6,0,17.5,4.2,22.5,10.9l0.9-9.8H963.1z M956.1,422.6c0-12.5-9-21.7-21.4-21.7
				c-12.3,0-21.5,9.3-21.5,21.7c0,12.4,9.2,21.7,21.5,21.7C947.1,444.3,956.1,435.1,956.1,422.6z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#2CBACB"
                        className="st2 path blue"
                        d="M1028.5,417.3v32.4h-6.8v-30.9c0-11.6-5.8-18.3-15.9-18.3c-9.9,0-17.8,8.4-17.8,18.9v30.3h-6.8v-54.3h4.9
				l1.4,9.6c4-6.6,11.3-10.7,20-10.7C1020.4,394.3,1028.5,403.8,1028.5,417.3z"
                    />
                    <path
                        strokeWidth="4"
                        stroke="#2CBACB"
                        className="st2 path blue"
                        d="M1061.7,424.6h-8.6v25.2h-6.8v-73.6h6.8v42.1h8.7l18.1-22.8h8.4l-20.7,26l22.7,28.3h-8.6L1061.7,424.6z"
                    />
                </g>
            </g>

        </svg>
    </div>
)

export default Loading
