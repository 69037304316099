import React, { Suspense, lazy, Fragment, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  setHeaderBackgroundColor,
  setBackgroundColor
} from '../../reducers/ThemeOptions'
import Loading from './Loading'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'

import { ToastContainer } from 'react-toastify'

const Dashboard = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 2500))
  return import('../../Pages/Dashboard')
})
const NewsDetailPage = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 2500))
  return import('../../Pages/NewsDetailPage')
})

const AppMain = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeaderBackgroundColor('bg-light header-text-light')
    )
    dispatch(
      setBackgroundColor('bg-light sidebar-text-light')
    )
    //dispatch(setEnableClosedSidebar(true))
  }, [dispatch])

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <AppHeader />
        <div className="app-main bg-dark">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Route exact path="/" component={Dashboard} />
              <Route path="/news/:id/:createdAt" component={NewsDetailPage} />
            </div>
          </div>
        </div>
      </Suspense>

      <ToastContainer />
    </Fragment>
  )
}

export default AppMain
