import React, { Fragment } from 'react';
import { Elastic } from 'react-burgers'
import PerfectScrollbar from 'react-perfect-scrollbar';
import Drawer from 'react-motion-drawer';
import Tabs from 'react-responsive-tabs';
import {
    Nav, Button, NavItem
} from 'reactstrap';

// Dropdown Tabs Content
import ChatExample from './TabsContent/ChatExample';
import TimelineEx from './TabsContent/TimelineExample';
import SysErrEx from './TabsContent/SystemExample';

const tabsContent = [
    {
        title: 'Messages',
        content: <ChatExample />
    },
    {
        title: 'Events',
        content: <TimelineEx />
    },
    {
        title: 'System Errors',
        content: <SysErrEx />
    },
];

function getTabs() {
    return tabsContent.map((tab, index) => ({
        title: tab.title,
        getContent: () => tab.content,
        key: index,
    }));
}
class HeaderRightDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openLeft: false,
            openRight: false,
            relativeWidth: false,
            width: 450,
            noTouchOpen: false,
            noTouchClose: false,
        };

    }

    render() {
        const {
            openRight,
        } = this.state;

        return (
            <Fragment>
                <Drawer
                    right
                    className="drawer-content-wrapper p-0"
                    width={450}
                    open={openRight}
                    onChange={open => this.setState({ openRight: open })}
                    noTouchOpen={false}
                    noTouchClose={false}
                >
                    <PerfectScrollbar>
                        <div className="drawer-nav-btn">
                            <Elastic
                                width={26}
                                lineHeight={2}
                                lineSpacing={5}
                                color='#6c757d'
                                padding='5px'
                                active={this.state.openRight}
                                onClick={() => this.setState({ openRight: false, openLeft: false })} />
                        </div>
                        <div className="dropdown-menu-header mb-0">
                            <div className="dropdown-menu-header-inner bg-deep-blue">
                                <div className="menu-header-content text-dark">
                                    <h5 className="menu-header-title">Notifications</h5>
                                    <h6 className="menu-header-subtitle">You have <b>21</b> unread messages</h6>
                                </div>
                            </div>
                        </div>
                        <Tabs tabsWrapperClass="body-tabs body-tabs-alt" transform={false} showInkBar={true}
                            items={getTabs()} />
                        <Nav vertical>
                            <NavItem className="nav-item-divider" />
                            <NavItem className="nav-item-btn text-center">
                                <Button size="sm" className="btn-shadow btn-wide btn-pill" color="focus">
                                    View all of your notifications
                                    </Button>
                            </NavItem>
                        </Nav>
                    </PerfectScrollbar>
                </Drawer>

                <div className="header-btn-lg">
                    <Elastic
                        width={26}
                        lineHeight={2}
                        lineSpacing={5}
                        color='#6c757d'
                        padding='5px'
                        active={this.state.openRight}
                        onClick={() => this.setState({ openRight: !openRight, openLeft: false })} />
                </div>
            </Fragment>
        )
    }
}

export default HeaderRightDrawer;