import React, { Fragment } from 'react'

import {
  IoIosAnalytics,
} from 'react-icons/io'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,

} from 'reactstrap'

import { AreaChart, Area, ResponsiveContainer } from 'recharts'

import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons'

import CountUp from 'react-countup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const data = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
  { name: 'Page C', uv: 2000, pv: 6800, amt: 2290 },
  { name: 'Page D', uv: 4780, pv: 7908, amt: 2000 },
  { name: 'Page E', uv: 2890, pv: 9800, amt: 2181 },
  { name: 'Page F', uv: 1390, pv: 3800, amt: 1500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
]

const HeaderDots = () => {
  return (
    <Fragment>
      <div className="header-dots">
        <UncontrolledDropdown>
          <DropdownToggle className="p-0 mr-2" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-success" />
              <IoIosAnalytics color="#3ac47d" fontSize="23px" />
            </div>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-xl rm-pointers">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-premium-dark">
                <div className="menu-header-content text-white">
                  <h5 className="menu-header-title">Users Online</h5>
                  <h6 className="menu-header-subtitle">
                    Recent Account Activity Overview
                  </h6>
                </div>
              </div>
            </div>
            <div className="widget-chart">
              <div className="widget-chart-content">
                <div className="icon-wrapper rounded-circle">
                  <div className="icon-wrapper-bg opacity-9 bg-focus" />
                  <i className="lnr-users text-white" />
                </div>
                <div className="widget-numbers">
                  <CountUp
                    start={0}
                    end={344}
                    separator=""
                    decimals={0}
                    decimal=","
                    prefix=""
                    useEasing={false}
                    suffix="k"
                    duration="15"
                  />
                </div>
                <div className="widget-subheading pt-2">
                  Profile views since last login
                </div>
                <div className="widget-description text-danger">
                  <span className="pr-1">
                    <CountUp
                      start={0}
                      end={176}
                      separator=","
                      delay={2}
                      decimals={0}
                      decimal=","
                      useEasing={false}
                      prefix=""
                      suffix="%"
                      duration="10"
                    />
                  </span>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              </div>
              <div className="widget-chart-wrapper">
                <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                  <AreaChart
                    data={data}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  >
                    <Area
                      type="monotoneX"
                      dataKey="uv"
                      stroke="var(--warning)"
                      fill="var(--warning)"
                      fillOpacity=".5"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <Nav vertical>
              <NavItem className="nav-item-divider mt-0"> </NavItem>
              <NavItem className="nav-item-btn text-center">
                <Button
                  size="sm"
                  className="btn-shine btn-wide btn-pill"
                  color="warning"
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faCog}
                    spin
                    fixedWidth={false}
                  />
                  Refresh List
                </Button>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Fragment>
  )
}

export default HeaderDots
